import { render, staticRenderFns } from "./ManualPsp.vue?vue&type=template&id=217fc03a&scoped=true&"
import script from "./ManualPsp.vue?vue&type=script&lang=js&"
export * from "./ManualPsp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "217fc03a",
  null
  
)

export default component.exports