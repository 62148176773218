<template>
    <div>
        <div class="fullscreen-page-title">
            {{ tokens.fullScreen.settings.toUpperCase() }}
        </div>

        <div class="setting-inner">
            <div class="setting-left" :class="{'hide-activity': disableActivityLog}">
                <div class="card-bg">
                    <div class="form-row fs">
                        <div class="form-title">{{ tokens.fullScreenSettings.updatePassword }}</div>
                    </div>
                    <div class="col">
                        <div class="form-row fs">
                            <label class="fs">{{ tokens.fullScreenSettings.currentPassword }}</label>
                            <div class="show-hide-password">
                                <input :type="password.showingOld ? 'text' : 'password'" ref="password" v-model="password.old" class="form-control fs light-aspin">
                                <i class="material-icons"
                                   @touchstart="password.showingOld = !password.showingOld"
                                   @mousedown="password.showingOld = !password.showingOld">{{password.showingOld ? 'visibility' : 'visibility_off' }}</i>
                            </div>
                        </div>
                        <div class="form-row fs">
                            <label class="fs">{{ tokens.fullScreenSettings.newPassword }}</label>
                            <div class="show-hide-password">
                                <input :type="password.showingUpdated ? 'text' : 'password'" v-model="password.updated" ref="password2"
                                       class="form-control fs light-aspin">
                                <i class="material-icons"
                                   @touchstart="password.showingUpdated = !password.showingUpdated"
                                   @mousedown="password.showingUpdated = !password.showingUpdated">{{password.showingUpdated ? 'visibility' : 'visibility_off' }}</i>
                            </div>
                        </div>
                        <div class="form-row fs">
                            <label class="fs">{{ tokens.fullScreenSettings.confirmNewPassword }}</label>
                            <div class="show-hide-password">
                                <input :type="password.showingConfirmed ? 'text' : 'password'" v-model="password.confirmed" ref="confirmed"
                                       class="form-control fs light-aspin">
                                <i class="material-icons"
                                   @touchstart="password.showingConfirmed = !password.showingConfirmed"
                                   @mousedown="password.showingConfirmed = !password.showingConfirmed">{{password.showingConfirmed ? 'visibility' : 'visibility_off' }}</i>
                            </div>
                        </div>
                    </div>
                    <div class="pad">
                        <div class="col">
                            <div class="form-row fs">
                                <button class="fs-normal" v-on:click="updatePassword">
                                    {{ tokens.fullScreenSettings.changePassword }}
                                </button>
                            </div>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
                <div class="activity-log card-bg activeLog" v-if="!disableActivityLog">
                    <div class="form-row fs">
                        <div class="form-title">{{ tokens.fullScreenDashboard.activityLog.toUpperCase() }}</div>
                    </div>
                    <div class="activity-list">
                        <li v-for="(activityLog, index) in dashboardData.logins.rows" :key="index">
                            <h5 class="date">
                                {{
                                    `${new Date(activityLog.time * 1000).toLocaleDateString()} ${new Date(activityLog.time * 1000).toLocaleTimeString()}`
                                }}</h5>
                            <p class="state">{{ `${tokens.fullScreenDashboard.loginFromIp} ${activityLog.ip}` }}</p>
                        </li>
                    </div>
                </div>
            </div>
            <div class="setting-right">
                <div :class="{hide: user.otp}" class="pad card-bg">
                    <div class="form-row fs">
                        <div class="form-title">{{ tokens.fullScreenSettings.activateProtection.toUpperCase() }}</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-row fs">
                                <label class="fs">{{ tokens.fullScreenSettings.activationForGoogle }}</label>
                                <input v-model="code" readonly class="form-control fs light-aspin"/>
                            </div>
                            <div class="form-row fs">
                                <label class="fs">{{ tokens.fullScreenSettings.activateAndEnter }}</label>
                                <input v-model="codeCheck" class="form-control fs light-aspin"/>
                            </div>
                        </div>
                    </div>
                    <div class="col qr">
                        <div class="form-row fs">
                            <label class="fs">{{ tokens.fullScreenSettings.qrCode }}</label>
                            <div class="qr-new">
                                <QrcodeVue :value="otpEnableData.otpauth_url" :size="150"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-row fs">
                        <button class="btn fs-normal" v-on:click="activateOtp">
                            {{ tokens.fullScreenSettings.activateProtection }}
                        </button>
                    </div>
                </div>
                <div :class="{hide: !user.otp}" class="pad">
                    <div class="form-row fs">
                        <div class="form-title">{{ tokens.fullScreenSettings.protectionActive.toUpperCase() }}</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-row fs">
                                <label class="fs">{{ tokens.fullScreenSettings.twoFactorCode }}</label>
                                <input v-model="codeCheck" class="form-control fs light-aspin"/>
                            </div>
                            <div class="form-row fs">
                                <button class="btn fs-normal" v-on:click="disableOtp">
                                    {{ tokens.fullScreenSettings.disableProtection }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="settings-footer">
            <div class="form-row fs card-bg currency-item">
                <div class="form-row fs">
                    <div class="form-title">{{ tokens.fullScreenSettings.dashboardCurrency.toUpperCase() }}</div>
                </div>
                <div class="currency fs">
                    <div class="item fs"
                         v-for="currency in currencies"
                         :key="currency"
                         v-on:click="changeCurrency(currency)"
                         :class="{active: activeCurrency === currency}"
                    >
                        <div class="radio" v-if="!aspinDesign">
                            <div class="checked"></div>
                        </div>
                        <img :src="staticBase+`currencies/${currency ? currency : 'USD'}.png`" alt="">
                        <div class="name">{{ currency ? currency : 'USD' }}</div>
                    </div>
                </div>
            </div>

            <div class="form-row fs card-bg">
                <div class="form-row fs">
                    <div class="form-title">{{ tokens.fullScreenSettings.language.toUpperCase() }}</div>
                </div>
                <div class="languages fs">
                    <div class="item fs"
                         v-for="lang in languages"
                         :key="lang"
                         v-on:click="changeLang(lang)"
                         :class="{active: langActive === lang}"
                    >
                        <div class="radio" v-if="!aspinDesign">
                            <div class="checked"></div>
                        </div>
                        <div class="flag fs small-flag" :class="'flag-'+lang"></div>
                        <div class="name">{{ rawTokens[lang] ? rawTokens[lang]._name : '' }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {OTP_ACTIVATE, OTP_DISABLE, PASSWORD_CHANGE} from "@/store/actions.type"
import {ACTIVE_CURRENCY, LANG, NOTIFICATION_ERROR} from "@/store/mutations.type"
import QrcodeVue from 'qrcode.vue'
import {BRAND, ASPIN_DESIGN, QR_TITLE, QR_BRAND_TITLE, STATIC_BASE} from "@/common/config"

export default {
    name: "Settings",
    components: {QrcodeVue},
    data() {
        return {
            password: {
                old: '',
                updated: '',
                confirmed: '',
                showingOld: false,
                showingUpdated: false,
                showingConfirmed: false
            },
            code: '',
            codeCheck: '',
            brand: BRAND,
            disableActivityLog: 1,
            aspinDesign: ASPIN_DESIGN,
            qrTitle: QR_TITLE,
            qrTitleBrand: QR_BRAND_TITLE,
            staticBase: STATIC_BASE
        }
    },
    methods: {
        updatePassword() {
            if (this.password.updated !== this.password.confirmed) {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_PASSWORDS_NOT_MATCH')
                return
            }

            this.$store.dispatch(PASSWORD_CHANGE, {
                old_password: this.password.old.trim(),
                new_password: this.password.updated.trim()
            })

            this.password.old = ''
            this.password.updated = ''
            this.password.confirmed = ''
        },
        activateOtp() {
            this.$store.dispatch(OTP_ACTIVATE, {
                code: this.codeCheck
            })

            this.codeCheck = ''
        },
        disableOtp() {
            this.$store.dispatch(OTP_DISABLE, {
                code: this.codeCheck
            })

            this.codeCheck = ''
        },
        changeCurrency(currency) {
            localStorage.activeCurrency = currency
            this.$store.commit(ACTIVE_CURRENCY, currency)
        },
        changeLang(lang) {
            this.$store.commit(LANG, lang)
        }
    },
    computed: {
        ...mapGetters(['user', 'otpEnableData', 'langActive', 'languages', 'tokens', 'rawTokens', 'dashboardData', 'currencies', 'activeCurrency'])
    },
    watch: {
        otpEnableData(val) {
            if(this.qrTitle) {
                val.otpauth_url = val.otpauth_url.replace(this.qrTitleBrand, this.qrTitle)
            }
            this.code = val.base32
        }
    }
}
</script>

<style scoped>
.row {
    flex-direction: row !important;
    align-items: stretch;
}

.col {
    flex: 1 1 40%;
    margin-bottom: 10px;
}

.pad {
    height: 100%;
}

.activity-log {
    overflow: hidden;
    height: 100%;
    max-block-size: 200px;
}

.setting-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.activity-log h3 {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 24px;
    color: var(--white);
}

.activity-list {
    height: calc(100% - 49px);
    overflow-y: scroll;
    padding-right: 20px;
}

.activity-list li {
    display: grid;
    grid-template-columns: repeat(2, auto);
    list-style: none;
    padding-bottom: 10px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 10px;
    align-items: center;
}

.setting-left.hide-activity {
    display: contents;
}

.activity-list li:last-child {
    margin-bottom: 0;
}

.aspin-design .activity-list li {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
    padding-top: 10px;
    border-top: 0.5px solid rgba(255, 255, 255, 0.2);
    margin-top: 10px;
}

.activity-list .date {
    font-weight: 400;
    font-size: 16px;
    color: #91BDFF;
    white-space: nowrap;
}

.activity-list .state {
    font-weight: 300;
    font-size: 18px;
    color: #C0C0C0;
    white-space: nowrap;
    text-align: right;
}

.setting-inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5vw;
}

.qr {
    grid-area: 2/2;
}

input, select {
    width: 100%;
    font-size: 1.2em;
}

img {
    height: 115px;
    width: 115px;
}

.languages,
.currency {
    display: flex;
    flex-wrap: wrap;
}

.languages .item {
    gap: 5px;
}

.languages .item,
.currency .item {
    padding: 8px;
    display: grid;
    grid-template-columns: max-content 16px 1fr;
    align-items: center;
    border-radius: 3px;
    border: 1px solid var(--border);
    cursor: pointer;
    font-weight: 400;
    transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
    font-size: 1.2em;
    margin: 0 30px 10px 0;
}

.form-title {
    text-transform: uppercase;
}

.currency .item img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    object-fit: cover;
}

.languages .item.active, .languages .item:hover,
.currency .item.active, .currency .item:hover {
    background-color: var(--background);
    color: var(--white);
}

.currency.fs .item .name {
    margin-left: 2px;
}

.languages .item .radio,
.currency .item .radio {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid var(--border);
    transition: border-color ease-in-out 0.3s;
    position: relative;
}

.languages .item .radio .checked,
.currency .item .radio .checked {
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: var(--white);
    top: 0;
    left: 0;
    display: none;
}

.languages .item.active .radio,
.currency .item.active .radio {
    border-color: transparent;
}

.languages .item:hover .radio,
.currency .item:hover .radio {
    border-color: var(--white);
}

.languages .item.active .radio .checked,
.currency .item.active .radio .checked {
    display: flex;
}

.languages .item .flag {
    margin-right: 10px;
}

.setting-left div:first-child {
    margin-top: 0;
}

.activity-log.full {
    min-height: 100%;
}

.qr-new {
    background: white;
    padding: 20px;
}

.aspin-design .qr-new {
    border-radius: 20px;
}

.aspin-design .date {
    color: var(--white-to-black);
}

.aspin-design .show-hide-password {
    background: transparent;
}

@media (min-width: 2000px) {

    .languages .item .flag {
        display: none;
    }
}

@media (max-width: 1400px) {
    .activity-log h3 {
        font-size: 20px;
    }

    .activity-list li {
        gap: 20px;
    }
}

@media (min-width: 1000px) {
    .aspin-design .languages .item,
    .aspin-design .currency .item{
        gap: .6vw;
    }

    .aspin-design .qr-new {
        border-radius: .3vw;
    }

    .show-hide-password {
        border: .1vw solid var(--background-footer);
        background: transparent;
    }

    .show-hide-password input {
        background: transparent;
        border: none;
    }

    .show-hide-password .form-control {
        padding: 0.4vw 3.6vw 0.4vw 0.625vw;
    }

    .currency.fs .item .name {
        margin-left: .4vw;
    }

    .activity-log {
        max-block-size: 14vw;
        margin-top: 1.5vw;
    }

    .setting-inner {
        margin-bottom: 1.5vw;
    }

    .activity-log h3 {
        margin-bottom: 1vw;
        font-size: 1.25vw;
    }

    .currency .item img {
        width: 1vw;
        height: 1vw;
        margin-right: .3vw;
    }

    .activity-list {
        height: calc(100% - 2.5vw);
        padding-right: 1vw;
    }

    .activity-list li {
        font-size: 1vw;
        padding-bottom: .5vw;
        border-bottom: .1vw solid rgba(255, 255, 255, 0.2);
    }

    .aspin-design .activity-list li {
        font-size: 1vw;
        padding-bottom: 0;
        border-bottom: none;
        padding-top: .5vw;
        border-top: .1vw solid rgba(255, 255, 255, 0.2);
    }

    .activity-list .date {
        font-size: 1vw;
    }

    .activity-list .state {
        font-size: .9vw;
    }

    .languages.fs .item,
    .currency.fs .item {
        padding: .4vw;
        border-radius: .5vw;
        font-size: .9vw;
        margin: 0 1.5vw .5vw 0;
        border: .1vw solid var(--background-footer);
    }

    .languages.fs .item .radio,
    .currency.fs .item .radio {
        width: .8vw;
        height: .8vw;
        border: .1vw solid var(--border);
        margin-right: .5vw;
    }

    .languages.fs .item .radio .checked,
    .currency.fs .item .radio .checked {
        width: .6vw;
        height: .6vw;
    }

    .languages.fs .flag {
        margin-right: .5vw;
    }

    .img-inner-fs {
        padding: 1.8vw;
        background: #0F111D;
        border: .1vw solid rgba(255, 255, 255, 0.3);
        border-radius: .4vw;
    }

    img.fs {
        height: 6vw;
        width: 6vw;
    }
}

@media (max-width: 1000px) {
    .setting-inner {
        margin-bottom: 20px;
    }

    .currency.fs .item {
        gap: 5px;
    }

    .show-hide-password .form-control {
        padding-right: 40px;
    }

    .activity-log {
        margin-top: 15px;
    }

    .languages,
    .currency {
        display: block;
        width: 100%;
    }

    .languages .item,
    .currency .item {
        margin: 0 0 10px 0;
    }

    .languages .item:last-child,
    .currency .item:last-child {
        margin: 0;
    }

    .setting-inner {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 20px;
    }


    .col.qr {
        grid-area: auto;
    }

    .form-row {
        margin-bottom: 20px;
    }

}

@media (max-width: 767px) {
    .col {
        margin-left: 0 !important;
        flex: 1 1 100%
    }
}

@media (max-width: 500px) {
    .activity-list .state {
        font-size: 12px;
    }

    .activity-list .date {
        font-size: 14px;
    }

    .aspin-design .activity-list .date {
        font-size: 9px;
    }
}
</style>
